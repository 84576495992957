import { useEffect, useState } from "react";
import supabase from "./supabase";
import "./style.css";
import { async } from "q";

function Counter() {
  const [count, setCount] = useState(0);

  return (
    <div>
      <span style={{ fontSize: "40px" }}>{count}</span>
      <button className="btn btn-large" onClick={() => setCount((c) => c + 1)}>
        +1
      </button>
    </div>
  );
}

function App() {
  /* 1. Define state variable */
  const [showForm, setShowForm] = useState(false);
  const [facts, setFacts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentCategory, setCurrentCategory] = useState("all");

  useEffect(
    function () {
      async function getMovies() {
        setIsLoading(true);

        let query = supabase.from("Movies").select("*");

        if (currentCategory !== "all")
          query = query.eq("category", currentCategory);

        const { data: Movies, error } = await query
          .order("votesInteresting", { ascending: false })
          .limit(1000);

        if (!error) setFacts(Movies);
        else alert("There was a problem getting data");
        setIsLoading(false);
      }
      getMovies();
    },
    [currentCategory]
  );

  return (
    <>
      <Header showForm={showForm} setShowForm={setShowForm} />

      {/* 2. Use state variable */}
      {showForm ? (
        <NewFactForm setFacts={setFacts} setShowForm={setShowForm} />
      ) : null}

      <main className="main">
        <CategoryFilter setCurrentCategory={setCurrentCategory} />
        {isLoading ? (
          <Loader />
        ) : (
          <FactList facts={facts} setFacts={setFacts} />
        )}
      </main>
    </>
  );
}

function Loader() {
  return <p className="message">...Loading...</p>;
}

function Header({ showForm, setShowForm }) {
  return (
    <header className="header">
      <div className="logo">
        <img src="ghost.png" alt="FavyMovie Logo" />
        <h1>
          Favy <br />
          Movie
        </h1>
      </div>
      <button
        className="btn btn-large btn-share-movie btn-open"
        onClick={() => setShowForm((show) => !show)}
      >
        {showForm ? "Close" : "Share Yours"}
      </button>
    </header>
  );
}

const CATEGORIES = [
  { name: "sci-fi", color: "#3b82f6" },
  { name: "superhero", color: "#16a34a" },
  { name: "horror", color: "#ef4444" },
  { name: "comedy", color: "#914900" },
  { name: "romance", color: "#db2777" },
  { name: "thriller", color: "#14b8a6" },
  { name: "action", color: "#f97316" },
  { name: "fantasy", color: "#8b5cf6" },
];

function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

function NewFactForm({ setFacts, setShowForm }) {
  const [title, setTitle] = useState("");
  const [year, setYear] = useState("");
  const [description, setDescription] = useState("");
  const [sourceToWatch, setSourceToWatch] = useState("");
  const [category, setCategory] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const descriptionLength = description.length;

  async function handleSubmit(e) {
    /* 1. Prevent the browser reload*/
    e.preventDefault();
    /* 2. Check if data valid, if so create a new fact*/
    if (
      title &&
      year &&
      description &&
      isValidHttpUrl(sourceToWatch) &&
      category &&
      descriptionLength <= 200
    ) {
      /* 3. Create a new fact object
      const newFact = {
        id: Math.round(Math.random() * 10000000),
        title,
        year,
        description,
        sourceToWatch,
        category,
        votesInteresting: 0,
        votesMindBlowing: 0,
        votesFalse: 0,
        createdIn: new Date().getFullYear(),
      };*/

      /* 3. Upload Movie to Supabase and receive the new Movie object */
      setIsUploading(true);
      const { data: newFact, error } = await supabase
        .from("Movies")
        .insert([{ title, year, description, sourceToWatch, category }])
        .select();
      setIsUploading(false);

      /* 4. Add the new fact to the UI: add the fact to state*/
      if (!error) setFacts((facts) => [newFact[0], ...facts]);
      /* 5. Reset the input fields back to empty*/
      setTitle("");
      setYear("");
      setDescription("");
      setSourceToWatch("");
      setCategory("");
      /* 6. Close the entire form*/
      setShowForm(false);
    }
  }

  return (
    <form className="fact-form" onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Movie Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        disabled={isUploading}
      />
      <input
        type="number"
        placeholder="Movie Year"
        value={year}
        onChange={(e) => setYear(e.target.value)}
        disabled={isUploading}
      />
      <input
        type="text"
        placeholder="Movie Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        disabled={isUploading}
      />
      <span>{200 - descriptionLength}</span>
      <input
        type="text"
        placeholder="Link to watch..."
        value={sourceToWatch}
        onChange={(e) => setSourceToWatch(e.target.value)}
        disabled={isUploading}
      />
      <select
        value={category}
        onChange={(e) => setCategory(e.target.value)}
        disabled={isUploading}
      >
        <option value="">Choose category:</option>
        {CATEGORIES.map((cat) => (
          <option key={cat.name} value={cat.name}>
            {cat.name.toUpperCase()}
          </option>
        ))}
      </select>
      <button className="btn btn-large" disabled={isUploading}>
        Post
      </button>
    </form>
  );
}

function CategoryFilter({ setCurrentCategory }) {
  return (
    <aside>
      <ul>
        <li className="category">
          <button
            className="btn btn-all-categories"
            onClick={() => setCurrentCategory("all")}
          >
            All
          </button>
        </li>
        {CATEGORIES.map((cat) => (
          <li key={cat.name} className="category">
            <button
              className="btn btn-category"
              style={{ backgroundColor: cat.color }}
              onClick={() => setCurrentCategory(cat.name)}
            >
              {cat.name}
            </button>
          </li>
        ))}
      </ul>
    </aside>
  );
}

function FactList({ facts, setFacts }) {
  if (facts.length === 0)
    return (
      <p className="message">
        No Movies in this category yet! Create the first one 😎!
      </p>
    );

  return (
    <section>
      <p>There are {facts.length} movies in this category. Add your own!</p>
      <ul className="facts-list">
        {facts.map((fact) => (
          <Fact key={fact.id} fact={fact} setFacts={setFacts} />
        ))}
      </ul>
    </section>
  );
}

function Fact({ fact, setFacts }) {
  const [isUpdating, setIsUpdating] = useState(false);
  const isDisputed =
    fact.votesInteresting + fact.votesMindBlowing < fact.votesFalse;

  async function handleVote(columnName) {
    setIsUpdating(true);
    const { data: updatedFact, error } = await supabase
      .from("Movies")
      .update({ [columnName]: fact[columnName] + 1 })
      .eq("id", fact.id)
      .select();
    setIsUpdating(false);
    if (!error)
      setFacts((facts) =>
        facts.map((f) => (f.id === fact.id ? updatedFact[0] : f))
      );
  }

  return (
    <li className="fact">
      {fact.title} - {fact.year}
      <p>
        {isDisputed ? (
          <span className="disputed">[🚨 BAD MOVIE ALERT]</span>
        ) : null}
        {fact.description}
        <a className="source" href={fact.sourceToWatch} target="_blank">
          (Source)
        </a>
      </p>
      <span
        className="tag"
        style={{
          backgroundColor: CATEGORIES.find((cat) => cat.name === fact.category)
            .color,
        }}
      >
        {fact.category}
      </span>
      <div className="vote-buttons">
        <button
          onClick={() => handleVote("votesInteresting")}
          disabled={isUpdating}
        >
          👍🏼 {fact.votesInteresting}
        </button>
        <button
          onClick={() => handleVote("votesMindBlowing")}
          disabled={isUpdating}
        >
          🤯 {fact.votesMindBlowing}
        </button>
        <button onClick={() => handleVote("votesFalse")} disabled={isUpdating}>
          ⛔️ {fact.votesFalse}
        </button>
      </div>
    </li>
  );
}

export default App;
